import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...props} components={components} mdxType="MDXLayout">
    <h1>{`wayback-css`}</h1>
    <p>{`Get the css for a wayback machine url.`}</p>
    <h2>{`Installation`}</h2>
    <pre><code {...{
        "className": "language-bash",
        "parentName": "pre"
      }}>{`npm install --save wayback-css
`}</code></pre>
    <h2>{`Usage`}</h2>
    <pre><code {...{
        "className": "language-javascript",
        "parentName": "pre"
      }}>{`const waybackCss = require('wayback-css')

waybackCss('google.com', '20151221') // => YYYYMMDDhhss timestamp format
  .then(doStuff)
  .catch(handleError)
`}</code></pre>
    <h2>{`License`}</h2>
    <p>{`MIT`}</p>
    <h2>{`Contributing`}</h2>
    <ol>

      <li {...{
        "parentName": "ol"
      }}>{`Fork it`}</li>


      <li {...{
        "parentName": "ol"
      }}>{`Create your feature branch (`}
        <inlineCode {...{
          "parentName": "li"
        }}>{`git checkout -b my-new-feature`}</inlineCode>
        {`)`}</li>


      <li {...{
        "parentName": "ol"
      }}>{`Commit your changes (`}
        <inlineCode {...{
          "parentName": "li"
        }}>{`git commit -am 'Add some feature'`}</inlineCode>
        {`)`}</li>


      <li {...{
        "parentName": "ol"
      }}>{`Push to the branch (`}
        <inlineCode {...{
          "parentName": "li"
        }}>{`git push origin my-new-feature`}</inlineCode>
        {`)`}</li>


      <li {...{
        "parentName": "ol"
      }}>{`Create new Pull Request`}</li>

    </ol>
    <p>{`Crafted with `}
      {`<`}
      {`3 by John Otander (`}
      <a {...{
        "href": "https://twitter.com/4lpine",
        "parentName": "p"
      }}>{`@4lpine`}</a>
      {`).`}</p>
  </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      